@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-selected {
    @apply border-dark-grey focus:border-dark-grey [&.MuiButton-outlinedPrimary]:border-primary [&.MuiButton-outlinedPrimary]:font-bold [&.MuiButton-outlinedPrimary]:bg-primary/5;
  }
}

html {
  scroll-snap-type: y proximity;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  max-width: 650px;
  min-width: 320px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 18px;
  }
}

* {
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 1 !important;
}
