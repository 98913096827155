@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500&display=swap');

html {
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  font-family: 'Karla';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}